<template>
  <div>
    <Card>
      <template #header>Ziyaret Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addZiyaret = true"
              >Ziyaret Ekle</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Ziyaretler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="updateValue(item)">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addZiyaret" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Ziyaret</h4>
      </template>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="tapuNo">Varlık Tapu Numarası</label>
            <select
              name="tapuNo"
              id="tapuNo"
              class="form-control"
              v-model="seller_id"
            >
              <option value="" selected>Seçiniz</option>
              <option value="123">Diğer</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="ziyaretN">Ziyaret Nedeni </label>
            <select
              name="ziyaretN"
              id="ziyaretN"
              class="form-control"
              v-model="reason"
            >
              <option value="" selected>Seçiniz</option>
              <option value="İlk Ziyaret">İlk Ziyaret</option>
              <option value="Tadilat">Tadilat</option>
              <option value="Şikayet">Şikayet</option>
              <option value="Periyodik Ziyaret">Periyodik Ziyaret</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="aciklama">Ziyaret Açıklaması </label>
            <textarea
              name="aciklama"
              class="form-control"
              id="aciklama"
              rows="4"
              v-model="note"
            ></textarea>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="date1">Ziyaret Tarihi </label>
            <input type="date" class="form-control" v-model="date" id="date1" />
          </div>
        </div>

        <div class="col-md-6 col-12 mb-2">
          <div class="form-group">
            <label for="zGerceklestiren">Ziyareti Gerçekleştiren </label>
            <select
              name="zGerceklestiren"
              id="zGerceklestiren"
              class="form-control"
              v-model="user_id"
            >
              <option value="" selected>Seçiniz</option>
              <option value="64c223516cbfc0b2260a9522">Diğer</option>
            </select>
          </div>
        </div>
      </div>
      <div class="bg-dveb-light my-2 p-4">
        <p class="text-bold">Checklist</p>
      </div>
      <div class="form-group">
        <label for="question1"
          >İlgili gayrimenkulün ilanı seri ilan sitelerinde yayınlanıyor
          mu?</label
        >
        <select
          name="question1"
          id="question1"
          class="form-control"
          v-model="question_1"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div v-if="question_1 === '1'" class="form-group text-center">
        <div class="row align-items-center">
          <div class="col-4">
            <b-form-checkbox v-model="question_1_1" name="check-button" switch>
              <span style="font-size: 0.9rem" id="sahibinden" class="mt-3"
                >Sahibinden</span
              >
            </b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox
              v-model="question_1_2"
              id="bankamdan"
              name="check-button"
              switch
            >
              <span style="font-size: 0.9rem" class="mt-3">Bankamdan</span>
            </b-form-checkbox>
          </div>
          <div class="col-4">
            <b-form-checkbox
              v-model="question_1_3"
              id="hepsiemlak"
              name="check-button"
              switch
            >
              <span style="font-size: 0.9rem" class="mt-3">Hepsiemlak</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="question2"
          >Gayrimenkulün anahtarı tarafımıza ulaştı mı?</label
        >
        <select
          name="question2"
          id="question2"
          class="form-control"
          v-model="question_2"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="2">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_2 === '1'">
        <div class="row">
          <div class="col-6">
            <label for="ulasmaTarihi">Ulaşma Tarihi</label>
            <input
              type="date"
              class="form-control"
              id="ulasmaTarihi"
              name="ulasmaTarihi"
              v-model="question_2_1"
            />
          </div>
          <div class="col-6">
            <label for="teslimAlan">Teslim Alan</label>
            <MultiSelect
              v-model="question_2_2"
              :options="teslimAlan"
              :placeholder="'Teslim alan kişi'"
              :multiple="false"
              :defaultValue="question_2_2 ? question_2_2 : ''"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="question3">Gayrimenkule girişte sorun yaşandı mı? </label>
        <select
          name="question3"
          id="question3"
          class="form-control"
          v-model="question_3"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_3 === '1'">
        <label for="yasananSorun">Yaşanan Sorun</label>
        <textarea
          name="yasananSorun"
          id="yasananSorun"
          cols="30"
          class="form-control"
          v-model="question_3_1"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="question4">Gayrimenkul kilidi değiştirildi mi? </label>
        <select
          name="question4"
          id="question4"
          class="form-control"
          v-model="question_4"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="2">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_4 === '1'">
        <label for="degistirmeTarihi">Değiştirme Tarihi</label>
        <input
          type="date"
          class="form-control"
          name="degistirmeTarihi"
          id="degistirmeTarihi"
          v-model="question_4_1"
        />
      </div>
      <div class="form-group">
        <label for="question5"
          >Gayrimenkule "SATILIK" brandası asıldı mı?
        </label>
        <select
          name="question5"
          id="question5"
          class="form-control"
          v-model="question_5"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_5 === '1'">
        <label for="brandAsil">Branda Asılma Tarihi</label>
        <input
          type="date"
          class="form-control"
          name="brandAsil"
          id="brandAsil"
          v-model="question_5_1"
        />
      </div>
      <div class="form-group">
        <label for="question6">Gayrimenkulün tadilat ihtiyacı var mı? </label>
        <select
          name="question6"
          id="question6"
          class="form-control"
          v-model="question_6"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="2">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_6 === '1'">
        <label for="tadilat">Tadilatın Gerekli Görülme Sebebi</label>
        <textarea
          name="tadilat"
          id="tadilat"
          cols="30"
          class="form-control"
          v-model="question_6_1"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="question7"
          >Gayrimenkulün yeni fotoğrafları çekildi mi?
        </label>
        <select
          name="question7"
          id="question7"
          class="form-control"
          v-model="question_7"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_7 === '1'">
        <div class="row align-items-center">
          <div class="col-6">
            <b-form-checkbox v-model="question_7_1" name="check-button" switch>
              <span style="font-size: 0.9rem" id="icMekan" class="mt-3"
                >İç Mekan</span
              >
            </b-form-checkbox>
          </div>
          <div class="col-6">
            <b-form-checkbox
              v-model="question_7_2"
              id="disMekan"
              name="check-button"
              switch
            >
              <span style="font-size: 0.9rem" class="mt-3">Dış Mekan</span>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="question8"
          >Ziyaret sonrasında ilan detayında değişmesi gereken bilgi var mı?
        </label>
        <select
          name="question8"
          id="question8"
          class="form-control"
          v-model="question_8"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_8 === '1'">
        <label for="degisiklikSebebi">Değişiklik Sebebi</label>
        <textarea
          name="degisiklikSebebi"
          id="degisiklikSebebi"
          cols="30"
          class="form-control"
          v-model="question_8_1"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="question9"
          >Gayrimenkul etrafında esnaf, emlakçı vs. Kişiler ile iletişim
          sağlandı mı?
        </label>
        <select
          name="question9"
          id="question9"
          class="form-control"
          v-model="question_9"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="2">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_9 === '1'">
        <label for="etrafEsnaf">İletişim Kurulan Kişiler</label>
        <textarea
          name="etrafEsnaf"
          id="etrafEsnaf"
          cols="30"
          class="form-control"
          v-model="question_9_1"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="question10">Belediyeden emlak beyanı verildi mi? </label>
        <select
          name="question10"
          id="question10"
          class="form-control"
          v-model="question_10"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="0">Hayır</option>
        </select>
      </div>
      <div class="form-group">
        <label for="question11"
          >Gayrimenkule ait aidat bilgisi alındı mı?
        </label>
        <select
          name="question11"
          id="question11"
          class="form-control"
          v-model="question_11"
        >
          <option value="" selected>Seçiniz</option>
          <option value="1">Evet</option>
          <option value="2">Hayır</option>
        </select>
      </div>
      <div class="form-group" v-if="question_11 === '1'">
        <label for="aidatAciklama">Aidat İle İlgili Açıklama</label>
        <textarea
          name="aidatAciklama"
          id="aidatAciklama"
          cols="30"
          class="form-control"
          v-model="question_11_1"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="question12"
          >Banka ile paylaşılması gereken diğer bilgiler</label
        >
        <textarea
          v-model="question_12"
          name="question12"
          class="form-control"
          id="question12"
          rows="3"
        ></textarea>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="resetValue"> Vazgeç </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="ziyaret"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("tr");
export default {
  name: "systemUsers",
  data() {
    return {
      teslimAlan: [
        { text: "Kişi 1", value: "64c223516cbfc0b2260a9522" },
        { text: "Kişi 2", value: "64c223516cbfc0b2260a9522" },
      ],
      addZiyaret: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Emlak Kodu", value: "bank_realty_code" },
        { text: "Ziyaret Amacı", value: "reason" },
        { text: "Ziyaret Tarihi", value: "date" },
        { text: "Ziyareti Gerçekleştiren", value: "name" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      seller_id: "",
      real_id: "",
      reason: "",
      note: "",
      date: "",
      user_id: "",
      question_1: "",
      question_1_1: "",
      question_1_2: "",
      question_1_3: "",
      question_2: "",
      question_2_1: "",
      question_2_2: "",
      question_3: "",
      question_3_1: "",
      question_4: "",
      question_4_1: "",
      question_5: "",
      question_5_1: "",
      question_6: "",
      question_6_1: "",
      question_7: "",
      question_7_1: "",
      question_7_2: "",
      question_8: "",
      question_8_1: "",
      question_9: "",
      question_9_1: "",
      question_10: "",
      question_11: "",
      question_11_1: "",
      question_12: "",
      name: "afsdfsf",
      will_watch: "1",
      status: "1",
      bank_realty_code: "AK-47",
      updateModel: false,
      visit_id: "",
    };
  },

  created() {
    this.propertyId = this.$route.params.id;
    this.getList();
  },
  methods: {
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/visit?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.propertyId
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let data = {
        seller_id: this.seller_id,
        real_id: "123",
        reason: this.reason,
        note: this.note,
        date: moment(this.date).format("DD/MM/YYYY"),
        user_id: this.user_id,
        question_1: this.question_1,
        question_1_1: this.question_1_1,
        question_1_2: this.question_1_2,
        question_1_3: this.question_1_3,
        question_2: this.question_2,
        question_2_1: this.question_2_1,
        question_2_2: this.question_2_2.value,
        question_3: this.question_3,
        question_3_1: this.question_3_1,
        question_4: this.question_4,
        question_4_1: this.question_4_1,
        question_5: this.question_5,
        question_5_1: this.question_5_1,
        question_6: this.question_6,
        question_6_1: this.question_6_1,
        question_7: this.question_7,
        question_7_1: this.question_7_1,
        question_7_2: this.question_7_2,
        question_8: this.question_8,
        question_8_1: this.question_8_1,
        question_9: this.question_9,
        question_9_1: this.question_9_1,
        question_10: this.question_10,
        question_11: this.question_11,
        question_11_1: this.question_11_1,
        question_12: this.question_12,
        name: this.name,
        will_watch: this.will_watch,
        status: this.status,
        bank_realty_code: this.bank_realty_code,
        user_token: this.myUser,
        property_id: this.$route.params.id,
      };
      if (!this.updateModel) {
        this.$ApiService
          .post("property/visit", data)
          .then((res) => {
            this.$generateNotification(this, "success", "Ziyaret Eklenmiştir.");
            this.getList();
            this.addZiyaret = false;
          })
          .catch((err) => {
            this.$generateNotification(this, "error", "Ziyaret Eklenemedi.");
          });
      } else {
        data["visit_id"] = this.visit_id;
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }
        this.$ApiService
          .put("property/visit", formData)
          .then((res) => {
            this.$generateNotification(
              this,
              "success",
              "Ziyaret Başarıyla Güncellendi."
            );
            this.getList();
            this.addZiyaret = false;
          })
          .catch((err) => {
            this.$generateNotification(
              this,
              "error",
              "Ziyaret Güncellenemedi."
            );
          });
      }
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    updateValue(item) {
      (this.seller_id = item.seller_id),
        (this.real_id = item.real_id),
        (this.reason = item.reason),
        (this.note = item.note),
        (this.date = moment(item.date, "DD/MM/YYYY").format("YYYY-MM-DD")),
        (this.user_id = item.user_id),
        (this.question_1 = item.question_1),
        (this.question_1_1 = item.question_1_1),
        (this.question_1_2 = item.question_1_2),
        (this.question_1_3 = item.question_1_3),
        (this.question_2 = item.question_2),
        (this.question_2_1 = item.question_2_1),
        (this.question_2_2 = item.question_2_2),
        (this.question_3 = item.question_3),
        (this.question_3_1 = item.question_3_1),
        (this.question_4 = item.question_4),
        (this.question_4_1 = item.question_4_1),
        (this.question_5 = item.question_5),
        (this.question_5_1 = item.question_5_1),
        (this.question_6 = item.question_6),
        (this.question_6_1 = item.question_6_1),
        (this.question_7 = item.question_7),
        (this.question_7_1 = item.question_7_1),
        (this.question_7_2 = item.question_7_2),
        (this.question_8 = item.question_8),
        (this.question_8_1 = item.question_8_1),
        (this.question_9 = item.question_9),
        (this.question_9_1 = item.question_9_1),
        (this.question_10 = item.question_10),
        (this.question_11 = item.question_11),
        (this.question_11_1 = item.question_11_1),
        (this.question_12 = item.question_12),
        (this.seller_id = item.seller_id),
        (this.name = item.name),
        (this.will_watch = item.will_watch),
        (this.status = item.status),
        (this.bank_realty_code = item.bank_realty_code),
        (this.visit_id = item._id.$oid),
        (this.updateModel = true),
        (this.addZiyaret = true);
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = "property/visit";
      this.deleteIds = `?user_token=${this.myUser}&visit_id=${item._id.$oid}`;
      this.$bvModal.show("modal-deleteOperationziyaret");
    },
    resetValue() {
      this.seller_id = "";
      (this.real_id = ""),
        (this.reason = ""),
        (this.note = ""),
        (this.date = ""),
        (this.user_id = ""),
        (this.question_1 = ""),
        (this.question_1_1 = ""),
        (this.question_1_2 = ""),
        (this.question_1_3 = ""),
        (this.question_2 = ""),
        (this.question_2_1 = ""),
        (this.question_2_2 = ""),
        (this.question_3 = ""),
        (this.question_3_1 = ""),
        (this.question_4 = ""),
        (this.question_4_1 = ""),
        (this.question_5 = ""),
        (this.question_5_1 = ""),
        (this.question_6 = ""),
        (this.question_6_1 = ""),
        (this.question_7 = ""),
        (this.question_7_1 = ""),
        (this.question_7_2 = ""),
        (this.question_8 = ""),
        (this.question_8_ = ""),
        (this.question_9 = ""),
        (this.question_9_1 = ""),
        (this.question_10 = ""),
        (this.question_11 = ""),
        (this.question_11_1 = ""),
        (this.question_12 = ""),
        (this.seller_id = ""),
        (this.name = ""),
        (this.will_watch = ""),
        (this.status = ""),
        (this.bank_realty_code = ""),
        (this.visit_id = "");
      this.addZiyaret = false;
      this.updateModel = false;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
<style>
.custom-control-input[type="checkbox"] {
  width: 30px !important;
  height: 30px !important;
}
</style>
